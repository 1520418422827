export enum RefundSteps {
  General = "general",
  TravelTo = "travelTo",
  TravelFrom = "travelFrom",
  Scholarship = "scholarship",
  Summary = "summary",
  Food = "food",
}

export enum CostGroupings {
  Main = "hovedplassering",
  Diet = "diett",
  Accommodation = "overnatting",
  MealDeduction = "måltidsfratrekk",
  OwnVehicle = "eget kjøretøy",
}

export enum TravelAndExpenseType {
  // Travel to/from (reise)
  GeneralTravelExpenses = "generelle reiseutgifter",
  GeneralTravelExpensesWithOptionalReceipt = "generelle reiseutgifter med valgfri kvittering",
  TravelWithOwnVehicle = "reise eget kjøretøy",
  TravelWithOwnVehicleRaisedRate = "reise eget kjøretøy med avtale om forhøyet sats",
  PassengerCompensation = "passasjergodtgjørelse",

  // Diet
  DietBetweenSixAndTwelve = "diettsats uten overnatting 6 til 12 timer",
  DietOverTwelveHours = "diettsats uten overnatting over 12 timer",
  DietWithAccomodation = "diettsats overnatting",

  // Stipend
  Stipend = "stipend",
}

export enum TravelAndExpenseStatus {
  Draft = "utkast",
  ToApproval = "til godkjenning",
  Approved = "godkjent",
  Rejected = "avvist",
  OrderCreated = "ordre opprettet",
}

export enum EligibleUserRefundStatus {
  Applied = "påmeldt",
  Completed = "fullført",
  Participated = "deltatt",
}

export enum EligibleCourseRefundStatus {
  Opened = "åpnet",
  Closed = "avsluttet",
}
