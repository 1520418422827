import { StatusType } from "../enums/courseApplicationStatus.enums";

export interface SingleCourseStorageData {
  id: number;
  userStatus: StatusType;
  appliedViaForm?: boolean;
}

export const getSingleCourseStorageData = (courseId: number): SingleCourseStorageData => {
  const singleCourseData = sessionStorage.getItem(`currentSingleCourseData-${courseId}`);

  const data: SingleCourseStorageData = singleCourseData
    ? (JSON.parse(singleCourseData) as SingleCourseStorageData)
    : { id: courseId, userStatus: StatusType.Interessert, appliedViaForm: false };

  return data;
};

export const setSingleCourseStorageData = (data: SingleCourseStorageData) => {
  sessionStorage.setItem(`currentSingleCourseData-${data.id}`, JSON.stringify(data));
};
