export enum StatusType {
  Påmeldt = "påmeldt",
  Interessert = "interessert",
  Søkt = "søkt",
  Godkjent = "godkjent",
  Venteliste = "venteliste",
  Avslått = "avslått",
  Avmeldt = "avmeldt",
}

export enum EligibleDropOutStatus {
  applied = "påmeldt",
  searched = "søkt",
  waitingList = "venteliste",
  approved = "godkjent",
  qualified = "kvalifisert",
}
